
import Link from "next/link";
import Checkbox from "../Checkbox";
import Input from "../Input";
import Button from "../Button";
import React, { useState } from "react";
import Email from "next-auth/providers/email";
import SignInConfirm from "./SignInConfirm";
import axios from "axios";

interface FormElements extends HTMLFormControlsCollection {
    usernameInput: HTMLInputElement
}
interface SignUpFormElement extends HTMLFormElement {
    readonly elements: FormElements
}

export default function SignInForm() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [apiError, setAPIError] = useState<null | Error>(null);
    const [response, setResponse] = useState();
    const [message, setMessage] = useState<string>("");
    const [email, setEmail] = useState("");
    const [isTOSAccepted, setIsTOSAccepted] = useState(false);


    const handleSubmit = async (e: React.FormEvent<SignUpFormElement>) => {
        e.preventDefault();
        setIsLoading(true);
        setAPIError(null);
        const API_URL = "/rest/api/v1/users/login";
        const form = e.target as HTMLFormElement;
        const formData = new FormData(form);
    
        try {
            const response = await axios.post(API_URL, Object.fromEntries(formData), {
              headers: { "Content-Type": "application/json" },
              validateStatus: () => true,
            });
            if (response.status === 200) {
                const responseData = response.data;
                setResponse(responseData);
                setEmail(formData.get("email") as string || "");

              } else if (response.status === 400) {
                setMessage("This user is not registered. Please sign up.");
              }
            } catch (error) {
              console.error("Something went wrong while submitting the form", error);
              setMessage("Failed to sign in. Please check your credentials.");
            } finally {
              setIsLoading(false);
            }
        };
  
    
    return (
        response ? (<SignInConfirm email={email} />) : (<form method="post" onSubmit={handleSubmit} className="w-[44rem] relative py-20 my-10 px-20 grow flex flex-col gap-[1.62rem] rounded-[1.375rem] border border-gray-100 bg-gray-50 items-center justify-start">
            <h1 className="text-[2.5rem] font-[500] font-semibold">
                Sign in
            </h1>
            <div className="flex flex-col items-center gap-4 w-full">
  <Input disabled={isLoading} type="email" required name="email" placeholder="Email">
    <span>Enter Email</span>
  </Input>
  <span className="text-sm text-[#443BBC] font-medium">
    You will receive an email with a link to sign in.
  </span>
</div>

            <div className="flex  w-full justify-center items-center">
                {/* <Checkbox disabled={isLoading} checked={isTOSAccepted} onChange={(e) => setIsTOSAccepted(e.target.checked)} label={
                    <span className="text-sm text-[#443BBC] font-medium flex gap-x-1">
                        Remember Me
                    </span>
                } /> */}
                   
                {/* <Link className=" text-[#443BBC] text-sm font-semibold underline" href='#'>
                    Forgot Password?</Link> */}
            </div>
            <div className="flex flex-col gap-7 w-full justify-between items-center">
                <Button disabled={isLoading} type="submit" fullWidth color="blue">
                    Sign In
                </Button>
                <span className="text-gray-400 font-medium leading-5 flex justify-center gap-x-1 ">
                    Dont have an account?
                    <Link className="grow text-[#443BBC] text-sm font-semibold underline" href='/signup'>
                        Sign Up
                    </Link>
                </span>
            </div>
            {message && (
          <div className="flex justify-center items-center p-2 grow bg-red-500 w-full rounded-2xl">
            {message}
          </div>
        )}
        </form>)

    );
}