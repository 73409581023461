import Button from "../Button";
import React from "react";

export interface ISignInConfirm {
    email: string;
    onResend?: React.MouseEventHandler<HTMLButtonElement>;
}

export default function SignInConfirm(props: ISignInConfirm) {
    const { email, onResend } = props;
    return (
        <div className="w-[44rem] relative py-20 px-20 my-10  grow flex flex-col rounded-[1.375rem] border border-gray-100 bg-gray-50 items-center justify-start">
            <img className="mb-[3.12rem]" src="/images/login/email-sent.svg" />
            <h1 className="xh1 mb-[1.88rem]">
                Verify your Email
            </h1>
            <p className="mb-2 leading-5 text-gray-950">
                We sent an email to with a link to verify your registration.
            </p>
            <p className="leading-5 text-gray-950">
                Please check your <span className="font-semibold"> spam folder</span> if you do not see it in your inbox.
            </p>
            <p className="mt-[3.12rem] leading-5 text-gray-950">
                The email was sent to <span className="font-semibold">{email}</span>
            </p>
            {onResend && (
                <div className="mt-[3.12rem] flex flex-col gap-7 w-full justify-between items-center">
                    <Button onClick={onResend} disabled={false} type="submit" fullWidth color="gray">
                        Change email address
                    </Button>
                </div>
            )}
        </div>
    );
}