import Footer from "~/components/Footer";
import AppBar from "~/layouts/AppBar";
import SignInForm from "~/components/Auth/SignIn";
import SignInConfirm from "~/components/Auth/SignInConfirm";

export default function SignUp() {
    return (
        <>
            <AppBar isDark>
            </AppBar>
            <main className="mx-[var(--app-bar-margin)] grow items-center flex flex-col  justify-start ">
                <SignInForm />
            </main>
            <Footer />
        </>
    );
}