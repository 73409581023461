import React from "react";

export interface IInput extends React.InputHTMLAttributes<HTMLInputElement> {
    children: React.ReactNode
}

let iID = 0;

export default function Input(props: IInput) {
    const { children, required, ...rest } = props;
    const thisLabelID = `label-${iID++}`;
    return (
        <div className="w-full grow flex flex-col gap-y-1 ">
            <label htmlFor={thisLabelID} className="pl-8 text-gray-950 text-sm font-medium">
                {children} {required && <sup className="text-red-600">*</sup>}
            </label>
            <input id={thisLabelID} className="py-5 px-6 flex rounded-[1.0625rem] border border-gray-300 focus:outline-gray-300 focus:ring-0 bg-white" {...rest} required={required} />

        </div>

    );
}