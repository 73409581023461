import React from "react";

export interface IButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    children: React.ReactNode;
    bg?: string;
    text?: string;
    fullWidth?: boolean;
    isDark?: boolean;
    color?: "blue" | "gray";
}
export default function Button(props: IButton) {
    const { children, isDark = false, bg = "white", text = "gray-950", fullWidth = false, color, ...rest } = props;
    let bgProp = "bg-white";
    let textProp = "text-gray-950";
    if (isDark) {
        bgProp = "bg-[#020618B2] hover:bg-[#01213D]";
        textProp = "text-white";
    } else if (color === "blue") {
        bgProp = "bg-[#443BBC]";
        textProp = "text-white";
    } else if (color === "gray") {
        bgProp = "bg-[#EBEFF5]";
        textProp = "text-black";
    }
    return (
        <button className={`flex ${fullWidth && "w-full grow"} disabled:bg-gray-400 disabled:text-white disabled:border-gray-700 disabled:shadow-none py-5 px-8 rounded-2xl justify-center items-center ${bgProp} ${textProp}`} {...rest}>
            {children}
        </button>
    );
}